import axios from 'axios';
import { getStoryblokApi } from '@storyblok/react';
import { useQuery } from '@tanstack/react-query';

interface StoryblokQueryOptions {
  version?: 'draft' | 'published';
  resolve_relations?: string;
  resolve_links?: 'url' | 'story';
  cv?: number;
}

interface UseStoryblokQueryConfig extends StoryblokQueryOptions {
  enabled?: boolean;
}

// Create a separate axios instance for platform space
const platformApi = axios.create({
  baseURL: 'https://api.storyblok.com/v2',
  params: {
    token: process.env.REACT_APP_PLATFORM_STORYBLOK_TOKEN,
  },
});

export const useStoryblokStories = (
  component: string,
  config: UseStoryblokQueryConfig = {},
  usePlatformSpace = false
) => {
  // Use regular Storyblok API for website space
  const websiteStoryblokApi = getStoryblokApi();

  const {
    version = process.env.REACT_APP_SHOW_DRAFT_CMS_CONTENT === 'false'
      ? 'published'
      : 'draft',
    resolve_relations = '1',
    resolve_links = 'url',
    enabled = true,
    ...rest
  } = config;

  return useQuery({
    queryKey: ['storyblok', 'stories', component, version, usePlatformSpace],
    queryFn: async () => {
      const params = {
        version,
        resolve_relations,
        resolve_links,
        filter_query: {
          component: {
            in: component,
          },
        },
        cv: Date.now(),
        ...rest,
      };

      if (usePlatformSpace) {
        // Use direct API call for platform space
        const { data } = await platformApi.get('/cdn/stories', {
          params,
        });
        return data.stories;
      } else {
        // Use existing Storyblok instance for website space
        const { data } = await websiteStoryblokApi.get('cdn/stories', params);
        return data.stories;
      }
    },
    enabled,
  });
};
