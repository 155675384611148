import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import './Tailwind.css';
import './App.scss';

import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import Cookie from './components/Cookie';
import Routes from './Routes';
import { ParallaxProvider } from 'react-scroll-parallax';
import GoogleAnalyticsProvider from './providers/GoogleAnalyticsProvider';
import { logPageView } from './analytics';
import AnalyticsTracker from './analytics/pageTracker';

import Header from './components/Hompage/Header';

export const PRELOADER_RUN_TIME = 5000;

// Wrapper component to add padding for the fixed header
const MainContentWrapper = ({ children }) => (
  <div className="tw-pt-24">{children}</div>
);

function App() {
  //

  // State
  const [isPreLoaderLoaded, setPreLoaderStatus] = useState(false);
  const [trackingEnabled, setTrackingEnabled] = useState(false);

  //

  // Effects
  useEffect(() => {
    const isLoaded = setTimeout(() => {
      setPreLoaderStatus(true);
      // Log the initial page view
      if (trackingEnabled) {
        logPageView();
      }
    }, PRELOADER_RUN_TIME);

    // Add scroll locking logic
    if (!isPreLoaderLoaded) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      clearTimeout(isLoaded);
      // Cleanup scroll lock
      document.body.style.overflow = 'unset';
    };
  }, [isPreLoaderLoaded, trackingEnabled]);

  return (
    <ParallaxProvider>
      <GoogleAnalyticsProvider setTrackingEnabled={setTrackingEnabled}>
        <Router>
          <Header isPreLoaderLoaded={isPreLoaderLoaded} />
          <ScrollToTop />
          <MainContentWrapper isPreLoaderLoaded={isPreLoaderLoaded}>
            <Routes isPreLoaderLoaded={isPreLoaderLoaded} />
          </MainContentWrapper>
          <Footer isPreLoaderLoaded={isPreLoaderLoaded} />
          <Cookie isPreLoaderLoaded={isPreLoaderLoaded} />
          <AnalyticsTracker trackingEnabled={trackingEnabled} />
        </Router>
      </GoogleAnalyticsProvider>
    </ParallaxProvider>
  );
}

export default App;
