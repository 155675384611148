import { useStoryblok, StoryblokComponent } from '@storyblok/react';

const BlogPage = () => {
  const story = useStoryblok('blog', {
    version:
      process.env.REACT_APP_SHOW_DRAFT_CMS_CONTENT === 'true'
        ? 'draft'
        : 'published',
  });

  return (
    <>{story && story.content && <StoryblokComponent blok={story.content} />}</>
  );
};

export default BlogPage;
