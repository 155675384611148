import { useEffect, useState } from 'react';
import { StoryblokComponent } from '@storyblok/react';

// Icons
import { HiChevronDown } from 'react-icons/hi';

// Types
import {
  MatrixGroupStoryblok,
  MatrixRowStoryblok,
} from '../../../../components/Storyblok/schema/component-types';

const FeatureMatrixGroup = ({ blok }: MatrixGroupStoryblok) => {
  //

  // State
  const [isExpanded, setIsExpanded] = useState(false);

  //

  // Variables
  const {
    feature_name = '',
    feature_rows = [],
    is_default_expanded = false,
  } = (blok as MatrixGroupStoryblok) || {};

  //

  // Handlers

  const handleToggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  //

  // Effects
  useEffect(() => {
    setIsExpanded(is_default_expanded);
  }, [is_default_expanded]);

  return (
    <>
      <div className="tw-mb-6 tw-flex tw-w-full tw-flex-col">
        <button
          className="tw-flex tw-p-2 tw-w-full tw-flex-row tw-items-center tw-justify-start tw-bg-antifreeze tw-text-gray-900 hover:tw-text-gray-700 tw-transition-all tw-duration-200"
          onClick={handleToggleExpanded}
        >
          <div className="tw-flex tw-w-12">
            <HiChevronDown
              className={`${isExpanded ? 'tw-rotate-180' : 'tw-rotate-0'}`}
              size={24}
            />
          </div>
          <p className="tw-text-sm tw-font-semibold">{feature_name}</p>
        </button>

        {isExpanded && (
          <>
            {feature_rows.map((featureRow: MatrixRowStoryblok) => (
              <StoryblokComponent key={featureRow._uid} blok={featureRow} />
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default FeatureMatrixGroup;
