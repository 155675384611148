export const getHeaderLinkStyles = ({ isActive, isPricing }) => {
  return `tw-ml-8 tw-transition-all tw-duration-300 tw-opacity-100 hover:tw-opacity-80 ${
    isActive
      ? isPricing
        ? 'tw-text-antifreeze tw-font-bold'
        : 'tw-text-peach tw-font-bold'
      : isPricing
      ? 'tw-text-antifreeze hover:tw-text-antifreeze tw-font-bold'
      : 'hover:tw-text-peach tw-font-normal'
  } `;
};

export const getFooterLinkStyles = ({ isActive, isPricing }) => {
  return `tw-transition-all tw-duration-300 tw-opacity-100 hover:tw-opacity-80 ${
    isActive
      ? isPricing
        ? 'tw-text-antifreeze tw-font-bold'
        : 'tw-text-peach tw-font-bold'
      : isPricing
      ? 'tw-text-antifreeze hover:tw-text-antifreeze tw-font-bold'
      : 'hover:tw-text-peach tw-font-normal'
  } `;
};
