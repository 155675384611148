// Icons
import { HiCheckCircle } from 'react-icons/hi';

// Types
import { PricingPlanStoryblok } from '../../../../components/Storyblok/schema/component-types';

interface PricingPlanProps {
  blok: PricingPlanStoryblok;
}
const PricingPlan = ({ blok }: PricingPlanProps) => {
  //

  // Variables
  const {
    title = '',
    short_description = '',
    full_description = '',
    price_per_month = 0,
    price_display_text = '',
    features = [],
    is_user_selectable,
    contact_mailto_address = '',
  } = blok || {};

  //

  // Handlers

  const handleSignUpClick = () => {
    window.location.href = `mailto:${contact_mailto_address}?subject=Sign up to Clearly ${title} tier`;
  };

  return (
    <div
      className={`tw-flex tw-w-full sm:tw-w-1/3 tw-flex-col tw-items-center tw-justify-between tw-rounded-xl tw-border tw-border-gray-200 tw-p-6 tw-py-12 tw-text-white `}
    >
      <div className="tw-flex tw-flex-col tw-items-center tw-justify-start">
        <h2 className="tw-mb-2 tw-text-center tw-text-2xl tw-font-semibold">
          {title}
        </h2>

        <p className="tw-mb-4 tw-text-center tw-text-sm tw-text-antifreeze">
          {short_description}
        </p>

        <div className="tw-flex tw-min-h-28 tw-items-start tw-justify-start">
          <p className="tw-text-center tw-text-sm">{full_description}</p>
        </div>

        <div className="tw-flex tw-min-h-16 tw-items-center tw-justify-center">
          {is_user_selectable ? (
            <>
              {price_display_text ? (
                <p className="tw-text-center tw-text-3xl tw-font-bold tw-text-white">
                  {price_display_text}
                </p>
              ) : (
                <p className="tw-text-center tw-text-white">
                  <span className="tw-text-5xl tw-font-bold tw-text-white">
                    ${price_per_month}
                  </span>{' '}
                  / month
                </p>
              )}
            </>
          ) : (
            <p className="tw-text-center tw-text-3xl tw-font-bold tw-text-white">
              Get in touch
            </p>
          )}
        </div>

        <div className="tw-mt-8 tw-flex tw-flex-col tw-items-start tw-justify-start">
          {features.map(({ title }) => (
            <div
              key={title}
              className="tw-mb-3 tw-flex tw-flex-row tw-justify-start"
            >
              <HiCheckCircle className="tw-mr-2 tw-size-4 tw-min-h-4 tw-min-w-4 tw-text-sm tw-text-gray-400" />
              <p className="tw-text-sm tw-text-gray-300">{title}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="tw-mt-8 tw-flex tw-w-full tw-flex-col tw-items-end tw-justify-end tw-pb-4">
        <button
          className="tw-bg-antifreeze tw-w-full tw-py-2 tw-transition-all tw-duration-200 tw-hover:opacity-80 tw-text-gray-900 tw-rounded tw-text-sm"
          onClick={handleSignUpClick}
        >
          {is_user_selectable ? 'Sign up' : 'Contact us'}
        </button>
      </div>
    </div>
  );
};

export default PricingPlan;
