import { StoryblokComponent } from '@storyblok/react';

// Hooks
import { useStoryblokStories } from '../../../hooks/storyblok/useStoryblokStories';

// Components
import {
  MatrixRowStoryblok,
  PricingPlanStoryblok,
} from '../../../components/Storyblok/schema/component-types';

const PricingPage = () => {
  //

  // Helpers
  const C = (key: string) => key.charAt(0).toUpperCase() + key.slice(1);

  //

  // Hooks
  const { data: pricingPlans = [] } = useStoryblokStories(
    'pricing_plan',
    {},
    true
  );

  const { data: featureMatrixGroups = [] } = useStoryblokStories(
    'matrix_group',
    {},
    true
  );

  //

  // Variables

  const availablePlanNames = ['standard', 'premium', 'enterprise'];

  const orderedPricingPlans = pricingPlans.sort(
    (a: PricingPlanStoryblok, b: PricingPlanStoryblok) =>
      a.content.price_per_month - b.content.price_per_month
  );

  const orderedFeatureMatrixGroups = featureMatrixGroups.sort(
    (a: MatrixRowStoryblok, b: MatrixRowStoryblok) =>
      a.content.order_index - b.content.order_index
  );

  return (
    <div className="tw-w-full">
      <div className="tw-w-full tw-flex tw-flex-col tw-item-center tw-justify-center tw-px-8">
        <h1 className="tw-text-center">Pricing</h1>

        <div className="tw-my-6 tw-flex tw-flex-col sm:tw-flex-row tw-gap-6 sm:tw-w-4/5 tw-max-w-screen-xl tw-m-auto">
          {orderedPricingPlans?.map(
            (pricingPlan: { uuid: string; content: unknown }) => (
              <StoryblokComponent
                key={pricingPlan.uuid}
                blok={pricingPlan.content}
              />
            )
          )}
        </div>

        <div className="tw-flex tw-gap-6 tw-w-4/5 tw-max-w-screen-xl tw-m-auto">
          <div className="tw-my-12 tw-flex tw-w-full tw-flex-col tw-text-white">
            <div className="tw-my-4 tw-flex tw-w-full tw-flex-col tw-items-end tw-justify-end">
              <div className="tw-flex tw-w-full sm:tw-w-1/2 tw-pl-12 sm:tw-pl-0 tw-flex-row tw-items-center tw-justify-end">
                {availablePlanNames.map((planName) => (
                  <div
                    key={planName}
                    className="tw-flex tw-h-10 tw-w-1/3 tw-flex-col tw-justify-end"
                  >
                    <p className="tw-text-sm sm:tw-text-lg tw-font-semibold tw-text-white">
                      {C(planName)}
                    </p>
                  </div>
                ))}
              </div>
            </div>

            {orderedFeatureMatrixGroups?.map(
              (featureMatrixGroup: { uuid: string; content: unknown }) => (
                <StoryblokComponent
                  key={featureMatrixGroup.uuid}
                  blok={featureMatrixGroup.content}
                />
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
