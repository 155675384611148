import { useLottie } from 'lottie-react';
import React, { useEffect, useState } from 'react';

// Preloader
import preLoader from '../assets/json/preloader.json';

// Constants
import { PRELOADER_RUN_TIME } from '../App';

function Preloader() {
  //

  // Options
  const OPTIONS = {
    animationData: preLoader,
    loop: false,
  };

  const { View } = useLottie(OPTIONS);

  //

  // State
  const [isFadeOut, setFadeOut] = useState(false);

  //

  // Effects
  useEffect(() => {
    const isLoaded = setTimeout(() => {
      setFadeOut(true);
    }, PRELOADER_RUN_TIME);
    return () => clearTimeout(isLoaded);
  }, []);

  return (
    <>
      <div className={`pre-loader-container ${isFadeOut && 'loaded'}`}>
        {View}
      </div>
    </>
  );
}
export default Preloader;
