import { HiMinus, HiOutlineCheck } from 'react-icons/hi';

// Types
import { MatrixRowStoryblok } from '../../../../components/Storyblok/schema/component-types';

const FeatureMatrixRow = ({ blok }: MatrixRowStoryblok) => {
  //

  // Variables
  const { title, tier_details = [] } = (blok as MatrixRowStoryblok) || {};

  return (
    <>
      <div className="tw-flex tw-flex-col sm:tw-flex-row tw-w-full tw-items-end sm:tw-items-center sm:tw-min-h-12">
        <div className="tw-flex tw-w-full sm:tw-w-1/2 tw-flex-row tw-items-start tw-justify-start tw-pl-12 tw-mr-0 tw-m-4 sm:tw-m-0 sm:tw-my-4 sm:tw-px-12">
          <p className="tw-text-sm tw-text-gray-200">{title}</p>
        </div>

        <div className="tw-flex tw-w-full sm:tw-w-1/2 tw-flex-row tw-items-center tw-justify-start tw-mb-4 tw-pl-12 sm:tw-pl-0 sm:tw-mb-0">
          {tier_details.map(({ is_included, display_text }, index) => (
            <div
              key={index}
              className="tw-flex tw-w-1/3 tw-flex-row tw-items-center tw-justify-start"
            >
              <p className="tw-text-sm tw-text-white">
                {display_text ? (
                  display_text
                ) : is_included ? (
                  <HiOutlineCheck className="tw-text-green-500" />
                ) : (
                  <HiMinus className="tw-text-gray-400" />
                )}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="tw-ml-6 tw-flex tw-h-px tw-bg-gray-200" />
    </>
  );
};

export default FeatureMatrixRow;
