import { storyblokEditable } from '@storyblok/react';

// Components
import TeamMemberCard from './TeamMemberCard';

// Hooks
import useTeamPage from '../../../hooks/team/useTeamPage';

export interface TeamPageProps {
  blok: {
    _uid: string;
    component: string;
    [key: string]: any;
  };
}

const TeamPage = ({ blok }: TeamPageProps) => {
  //

  // Hooks
  const {
    data: { isLoadingTeamPage = true, teamMembers = [], teamPageContent },
  } = useTeamPage();

  //

  // Variables
  const { title, subtitle } = teamPageContent || {};

  return (
    <div
      className="tw-flex tw-flex-col tw-w-full tw-justify-center tw-items-center tw-px-8 tw-mb-8"
      {...storyblokEditable(blok)}
    >
      <div
        className={`tw-w-full sm:tw-w-2/3 lg:tw-w-2/3 tw-max-w-[900px] tw-flex tw-flex-col tw-justify-center tw-py-16 tw-transition-all tw-duration-1000 ${
          isLoadingTeamPage
            ? 'tw-opacity-0 tw-mt-[-20px]'
            : 'tw-opacity-100 tw-mt-0'
        }`}
      >
        <h3 className="tw-text-white tw-text-center">{title}</h3>
        <p className="tw-text-white tw-text-center tw-pt-8 tw-leading-normal">
          {subtitle}
        </p>
      </div>

      <div
        className={`tw-w-full md:tw-w-4/5 lg:tw-w-2/3 tw-max-w-[900px] md:tw-ml-[0.5rem] tw-flex tw-flex-wrap tw-justify-left tw-gap-6 tw-min-h-[80vh] tw-transition-all tw-duration-1000 ${
          isLoadingTeamPage
            ? 'tw-opacity-0 tw-mt-[-20px]'
            : 'tw-opacity-100 tw-mt-0'
        }`}
      >
        {teamMembers.map((teamMember) => (
          <TeamMemberCard key={teamMember.name} teamMember={teamMember} />
        ))}
      </div>
    </div>
  );
};

export default TeamPage;
