import { useState } from 'react';
import { NavLink } from 'react-router-dom';

// Helpers
import { getFooterLinkStyles } from '../helpers/calculateStyles';

// Constants
const FOOTER_ITEMS = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'Product',
    url: '/product',
    dropDowns: [
      {
        title: 'Understand',
        url: '/understand',
      },
      {
        title: 'Optimise',
        url: '/optimise',
      },
      {
        title: 'Transition',
        url: '/transition',
      },
    ],
  },
  {
    title: 'About us',
    url: '/about',
    dropDowns: [
      {
        title: 'About Clearly',
        url: '/about',
      },
      {
        title: 'The Team',
        url: '/team',
      },
      {
        title: 'Careers',
        url: 'https://app.otta.com/companies/clearly?_gl=1*ikq3kq*_gcl_au*MTk0NzU4ODgyOC4xNzA4NTMyNjAy&_ga=2.54587435.1897338477.1710839600-1112123280.1708532602',
      },
    ],
  },
  {
    title: 'Blog',
    url: '/blog',
  },
  {
    title: 'Contact us',
    url: '/contact',
  },
  {
    title: 'Pricing',
    url: '/pricing',
  },
];

const FooterLinks = () => {
  //

  // State
  const [activeFooterParentGroup, setActiveFooterParentGroup] = useState(null);

  //

  // Handlers
  const handleFooterParentGroupClick = (title) => {
    setActiveFooterParentGroup(title);
  };

  return (
    <div className="tw-flex tw-flex-col tw-items-start tw-justify-start tw-space-y-3">
      {FOOTER_ITEMS.map(({ title, url, dropDowns = [] }) => (
        <div key={title}>
          {dropDowns?.length ? (
            <>
              <button
                className="tw-font-normal"
                onClick={() => handleFooterParentGroupClick(title)}
              >
                {title}
              </button>
              {activeFooterParentGroup === title && (
                <div className="tw-flex tw-flex-col tw-space-y-2 tw-ml-3">
                  {dropDowns.map(({ title: dropTitle, url: dropUrl }) => (
                    <NavLink
                      to={dropUrl}
                      target={dropUrl.includes('http') ? '_blank' : '_self'}
                      className={({ isActive }) =>
                        getFooterLinkStyles({
                          isActive,
                          isPricing: dropUrl.includes('pricing'),
                        })
                      }
                    >
                      {dropTitle}
                    </NavLink>
                  ))}
                </div>
              )}
            </>
          ) : (
            <NavLink
              to={url}
              className={({ isActive }) =>
                getFooterLinkStyles({
                  isActive,
                  isPricing: url.includes('pricing'),
                })
              }
            >
              {title}
            </NavLink>
          )}
        </div>
      ))}
    </div>
  );
};
export default FooterLinks;
