export const assets = {
  mobileCarGIF: `/assets/gif/Clearly-mobile-car-lanes-v3.gif`,
  blueMobile: `/assets/img/backgrounds/Blue-Mobile.png`,
  blue: `/assets/img/backgrounds/Blue.png`,
  branchingAlternative: `/assets/img/backgrounds/branching-mobile.png`,
  branching: `/assets/img/backgrounds/branching.png`,
  carWithTick: `/assets/img/backgrounds/Car_with_tick.png`,
  carWithBlackBackground: `/assets/img/backgrounds/Car-with-black-background.png`,
  Car: `/assets/img/backgrounds/Car.png`,
  background1: `/assets/img/backgrounds/jared-murray-NSuufgf-BME-unsplash.jpg`,
  background2: `/assets/img/backgrounds/deva-darshan-2XUfjLp7HYY-unsplash.jpg`,
  background3: `/assets/img/backgrounds/pexels-kelly-3997067.jpg`,
  greenMobile: `/assets/img/backgrounds/Green-Mobile.png`,
  green: `/assets/img/backgrounds/Green.png`,
  grid: `/assets/img/backgrounds/Grid.png`,
  barrierMobile: `/assets/img/backgrounds/Mobile-Barrier.png`,
  mobileGrid: `/assets/img/backgrounds/Mobile-Grid.png`,
  pageNotFound: `/assets/img/backgrounds/page-not-found.png`,
  dataFusionMobile: `/assets/img/backgrounds/Red-Mobile.png`,
  dataFusion: `/assets/img/backgrounds/Red.png`,
  truck: `/assets/img/backgrounds/Truck-with-black-background.png`,
  truckPathRight: `/assets/img/backgrounds/Truck.png`,
  vanWithTick: `/assets/img/backgrounds/Van_with_tick.png`,
  vanWithBlackBG: `/assets/img/backgrounds/Van-with-black-background.png`,
  van: `/assets/img/backgrounds/Van.png`,
  yellowMobile: `/assets/img/backgrounds/Yellow-Mobile.png`,
  yellow: `/assets/img/backgrounds/Yellow.png`,
  mainIllustration: `/assets/webp/ABOUT-US-OVERVIEW-DESKTOP-NOLOGO.webp`,
  mainIllustrationAlternative: `/assets/webp/ABOUT-US-OVERVIEW-MOBILE-NOLOGO.webp`,
  tollBooth: `/assets/svgs/Back-of-tollbooth.svg`,
  barrier: `/assets/svgs/barrier.svg`,
  worldMap: `/assets/svgs/ABOUT-US-WORLD-COMBINED-DESKTOP-v3.svg`,
  worldMobileMap: `/assets/svgs/ABOUT-US-WORLD-MAP-COMBINED-MOBILE-v3.svg`,
  angleDown: `/assets/svgs/angleDown.svg`,
  arrowRight: `/assets/svgs/arrowRight.svg`,
};
