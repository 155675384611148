import { useState } from 'react';
import { NavLink } from 'react-router-dom';

// SVGs
import { ReactComponent as Logo } from '../../assets/svgs/logo.svg';

// Helpers
import { getHeaderLinkStyles } from '../../helpers/calculateStyles';

// Constants
const NAVBAR_ITEMS = [
  {
    title: 'Product',
    url: '/product',
    dropDowns: [
      {
        title: 'Understand',
        url: '/understand',
      },
      {
        title: 'Optimise',
        url: '/optimise',
      },
      {
        title: 'Transition',
        url: '/transition',
      },
    ],
  },
  {
    title: 'About us',
    url: '/about',
    dropDowns: [
      {
        title: 'About Clearly',
        url: '/about',
      },
      {
        title: 'The Team',
        url: '/team',
      },
      {
        title: 'Careers',
        url: 'https://app.otta.com/companies/clearly?_gl=1*ikq3kq*_gcl_au*MTk0NzU4ODgyOC4xNzA4NTMyNjAy&_ga=2.54587435.1897338477.1710839600-1112123280.1708532602',
      },
    ],
  },
  {
    title: 'Blog',
    url: '/blog',
  },
  {
    title: 'Contact us',
    url: '/contact',
  },
];

interface HeaderProps {
  isPreLoaderLoaded: boolean;
}
const Header = ({ isPreLoaderLoaded }: HeaderProps) => {
  //

  // State
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  //

  // Handlers
  const handleMouseEnter = (title) => {
    setActiveDropdown(title);
  };

  const handleMouseLeave = () => {
    setActiveDropdown(null);
  };

  const handleToggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div
      className={`tw-fixed tw-top-0 tw-left-0 tw-right-0 tw-w-full tw-p-4 md:tw-p-8 tw-flex tw-justify-between tw-z-50 tw-bg-black tw-transition-all tw-duration-500 ${
        isPreLoaderLoaded ? 'tw-opacity-100' : 'tw-opacity-0'
      }`}
    >
      <div className="tw-flex tw-w-2/3 tw-flex-row tw-items-center tw-justify-start">
        <NavLink to="/" onMouseEnter={() => handleMouseEnter('')}>
          <div className="tw-w-28 tw-transition-all tw-duration-200 hover:tw-opacity-80">
            <Logo />
          </div>
        </NavLink>

        <div className="tw-w-4/5 tw-justify-start tw-z-50 tw-hidden md:tw-flex">
          {NAVBAR_ITEMS.map(({ title, url, dropDowns = [] }) => (
            <div
              key={title}
              className="tw-relative"
              onMouseEnter={() => handleMouseEnter(title)}
            >
              {dropDowns.length ? (
                <div className="tw-h-full tw-ml-6">
                  <button className="tw-text-white tw-whitespace-nowrap hover:tw-text-peach tw-font-normal tw-opacity-100 hover:tw-opacity-80 tw-transition-all tw-duration-300">
                    {title}
                  </button>
                  {/* Dropdown container */}
                  <div
                    onMouseLeave={handleMouseLeave}
                    className={`tw-absolute tw-left-2 tw-mt-0 tw-transform tw-transition-all tw-duration-200 tw-ease-out tw-rounded tw-shadow-lg tw-pb-6 tw-p-4 tw-min-w-48 tw-z-50 tw-bg-black
                      ${
                        activeDropdown === title
                          ? 'tw-opacity-100 tw-translate-y-0 tw-scale-100'
                          : 'tw-opacity-0 tw-translate-y-2 tw-scale-95 tw-pointer-events-none'
                      }`}
                  >
                    {dropDowns.map(({ title: dropTitle, url: dropUrl }) => (
                      <NavLink
                        key={dropTitle}
                        to={dropUrl}
                        target={dropUrl.includes('http') ? '_blank' : '_self'}
                        className="tw-text-white tw-font-normal tw-border-b-2 tw-pl-2 hover:tw-font-bold hover:tw-text-peach hover:tw-border-b-peach tw-opacity-100 hover:tw-opacity-80 tw-transition-all tw-duration-300 tw-block tw-py-2"
                      >
                        {dropTitle}
                      </NavLink>
                    ))}
                  </div>
                </div>
              ) : (
                <NavLink
                  to={url}
                  onMouseEnter={() => handleMouseEnter(title)}
                  className={({ isActive }) =>
                    getHeaderLinkStyles({
                      isActive,
                      isPricing: false,
                    })
                  }
                >
                  {title}
                </NavLink>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="tw-hidden md:tw-flex tw-w-1/3 tw-flex-row tw-items-center tw-justify-end">
        <NavLink
          to="/pricing"
          className={({ isActive }) =>
            getHeaderLinkStyles({ isActive, isPricing: true })
          }
        >
          Pricing
        </NavLink>

        <NavLink
          to="/contact"
          className="tw-bg-antifreeze tw-whitespace-nowrap tw-text-gray-900 tw-flex tw-flex-col tw-items-center tw-justify-center tw-px-4 tw-py-3 tw-rounded-sm tw-ml-8 tw-opacity-100 hover:tw-opacity-80 tw-transition-all tw-duration-300"
        >
          Request a demo
        </NavLink>
      </div>

      <div className="tw-flex md:tw-hidden tw-w-1/3 tw-flex-row tw-items-center tw-justify-end">
        <button
          className="tw-bg-antifreeze tw-px-4 tw-py-3 tw-rounded-sm tw-text-gray-900"
          onClick={handleToggleMobileMenu}
        >
          Menu
        </button>
      </div>

      {/* Mobile Menu Overlay */}
      <div
        className={`tw-fixed tw-inset-0 tw-bg-black tw-z-50 tw-transition-all tw-duration-300 ${
          isMobileMenuOpen
            ? 'tw-opacity-100'
            : 'tw-opacity-0 tw-pointer-events-none'
        }`}
      >
        <div className="tw-p-4 tw-flex tw-justify-between tw-items-center">
          <NavLink to="/" onClick={() => setIsMobileMenuOpen(false)}>
            <div className="tw-w-28">
              <Logo />
            </div>
          </NavLink>
          <button
            className="tw-text-antifreeze tw-px-4 tw-py-3 tw-rounded-sm"
            onClick={handleToggleMobileMenu}
          >
            Close
          </button>
        </div>

        <div className="tw-flex tw-flex-col tw-p-4">
          <div className="tw-flex tw-flex-col tw-space-y-6">
            {NAVBAR_ITEMS.map(({ title, url, dropDowns = [] }) => (
              <div key={title}>
                {dropDowns.length ? (
                  <>
                    <button
                      className="tw-text-white tw-font-bold tw-text-xl hover:tw-text-peach tw-transition-all tw-duration-300"
                      onClick={() =>
                        setActiveDropdown(
                          activeDropdown === title ? null : title
                        )
                      }
                    >
                      {title}
                    </button>
                    <div
                      className={`tw-mt-4 tw-ml-2 tw-space-y-4 tw-transition-all tw-duration-300 ${
                        activeDropdown === title ? 'tw-block' : 'tw-hidden'
                      }`}
                    >
                      {dropDowns.map(({ title: dropTitle, url: dropUrl }) => (
                        <NavLink
                          key={dropTitle}
                          to={dropUrl}
                          target={dropUrl.includes('http') ? '_blank' : '_self'}
                          onClick={() => setIsMobileMenuOpen(false)}
                          className="tw-block tw-text-xl tw-font-normal tw-text-white hover:tw-text-peach tw-transition-all tw-duration-300"
                        >
                          {dropTitle}
                        </NavLink>
                      ))}
                    </div>
                  </>
                ) : (
                  <NavLink
                    to={url}
                    onClick={() => setIsMobileMenuOpen(false)}
                    className={({ isActive }) =>
                      `tw-text-xl tw-font-bold ${
                        isActive
                          ? 'tw-text-peach '
                          : 'tw-text-white hover:tw-text-peach'
                      } tw-transition-all tw-duration-300`
                    }
                  >
                    {title}
                  </NavLink>
                )}
              </div>
            ))}
          </div>

          {/* Additional mobile menu items */}
          <div className="tw-flex tw-flex-col tw-w-1/2 tw-justify-start tw-items-start tw-mt-16 tw-space-y-6">
            <NavLink
              to="/pricing"
              onClick={() => setIsMobileMenuOpen(false)}
              className={({ isActive }) =>
                `tw-text-xl ${
                  isActive
                    ? 'tw-text-antifreeze tw-font-bold'
                    : 'tw-text-antifreeze'
                } tw-transition-all tw-duration-300`
              }
            >
              Pricing
            </NavLink>

            <NavLink
              to="/contact"
              onClick={() => setIsMobileMenuOpen(false)}
              className="tw-bg-antifreeze tw-text-gray-900 tw-text-center tw-px-4 tw-py-3 tw-rounded-sm tw-transition-all tw-duration-300"
            >
              Request a demo
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
