import { getStoryblokApi } from '@storyblok/react';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

export interface TeamMember {
  name: string;
  role: string;
  imageUrl: string;
  shortBio: string;
  extendedBio: string;
  linkedInUrl: string;
  content: {
    filename: string;
  }[];
  orderIndex: number;
}

export interface TeamPageContent {
  title: string;
  subtitle: string;
}

interface TeamPageData {
  isLoadingTeamPage: boolean;
  teamMembers: TeamMember[];
  teamPageContent: TeamPageContent;
}

interface TeamPageResponse {
  data: TeamPageData;
}

const useTeamPage = (): TeamPageResponse => {
  //

  // State
  const [isLoadingTeamPage, setIsLoadingTeamPage] = useState(true);

  //

  // Query functions
  const fetchTeamPage = async (): Promise<TeamPageContent> => {
    const storyblokApi = getStoryblokApi();
    const { data } = await storyblokApi.get('cdn/stories', {
      version:
        process.env.REACT_APP_SHOW_DRAFT_CMS_CONTENT === 'true'
          ? 'draft'
          : 'published',
      content_type: 'team_page',
      per_page: 1,
    });

    // Get the first (and only) team page content
    const pageContent = data.stories[0].content || {};

    return {
      title: pageContent?.title || '',
      subtitle: pageContent?.subtitle || '',
    };
  };

  const fetchTeamMembers = async (): Promise<TeamMember[]> => {
    const storyblokApi = getStoryblokApi();

    const { data } = await storyblokApi.get('cdn/stories', {
      version:
        process.env.REACT_APP_SHOW_DRAFT_CMS_CONTENT === 'true'
          ? 'draft'
          : 'published',
      content_type: 'team_member',
    });

    const mappedTeamMembers = data.stories.map((story: any) => ({
      name: story.content.name,
      role: story.content.role,
      imageUrl: story.content.image?.[0]?.filename || '',
      shortBio: story.content.shortBio,
      extendedBio: story.content.extendedBio,
      linkedInUrl: story.content.linkedInUrl,
      orderIndex: story.content.orderIndex,
    }));

    const alphabeticallyOrderedTeamMembers = mappedTeamMembers.sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    return alphabeticallyOrderedTeamMembers;
  };

  //

  // Queries
  const { data: teamMembers, isLoading } = useQuery({
    queryKey: ['team_members'],
    queryFn: fetchTeamMembers,
  });
  const { data: teamPageContent, isLoading: isContentLoading } = useQuery({
    queryKey: ['team_page'],
    queryFn: fetchTeamPage,
  });

  //

  // Effects

  // After both have completed loading, set the loading state to false after 200ms
  useEffect(() => {
    if (!isLoading && !isContentLoading && isLoadingTeamPage) {
      setTimeout(() => {
        setIsLoadingTeamPage(false);
        // Add a small delay to mimic the delay used by Motion components
      }, 200);
    }
  }, [isLoading, isContentLoading, isLoadingTeamPage]);

  return {
    data: {
      // Loading
      isLoadingTeamPage,

      // Data
      teamMembers,
      teamPageContent,
    },
  };
};

export default useTeamPage;
