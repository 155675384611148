// Types
import { TeamMember } from '../../../hooks/team/useTeamPage';

interface TeamMemberCardProps {
  teamMember: TeamMember;
}

const TeamMemberCard = ({ teamMember }: TeamMemberCardProps) => {
  //

  // Variables
  const {
    name = '',
    role = '',
    shortBio = '',
    linkedInUrl = '',
    imageUrl = '',
  } = teamMember || {};

  const hardTrimmedBio = shortBio.slice(0, 200);

  return (
    <div className="tw-w-full sm:tw-w-[calc(50%-1rem)] lg:tw-w-[calc(33%-1rem)] tw-pb-10">
      <div className="tw-aspect-square tw-w-full tw-overflow-hidden">
        <img
          src={imageUrl}
          alt="team"
          className="tw-w-full tw-h-full tw-object-cover"
        />
      </div>
      <h3 className="tw-font-semibold tw-text-md tw-mt-4 tw-text-white">
        {name}
      </h3>
      <div className="tw-flex tw-flex-row tw-items-start tw-justify-between">
        <p className="tw-text-rose tw-mt-2 ">{role}</p>
        <a
          href={linkedInUrl}
          target="_blank"
          rel="noreferrer"
          className="tw-text-white tw-text-sm tw-transition-opacity tw-mt-2.5 tw-duration-200 hover:tw-opacity-80"
        >
          <img
            src="/assets/img/icons/linked_in_white.png"
            alt="linkedin"
            className="tw-w-4 tw-h-4 tw-mr-2"
          />
        </a>
      </div>
      <div className="md:tw-min-h-20">
        <p className={`tw-text-sm tw-text-white tw-mt-4`}>{hardTrimmedBio}</p>
      </div>
    </div>
  );
};

export default TeamMemberCard;
